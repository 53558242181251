<template>
  <div>
    <a-card style="background-color: #fff;">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="销售订单号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-input v-model="queryData.saleDocketCode" placeholder="请输入销售订单号" />
                </a-form-item>
              </a-col>
              
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="分销商" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.distributorCode" style="width: 100%" placeholder="请选择分销商"
                            option-label-prop="label">
                    <a-select-option value="20220601001" label="优加惠品">
                      优加惠品
                    </a-select-option>
                    <a-select-option value="20221215001" label="好乐滋">
                      好乐滋
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.type" style="width: 100%" placeholder="请选择类型"
                            option-label-prop="label">
                    <a-select-option value="1" label="次票">次票</a-select-option>
                    <a-select-option value="3" label="储值卡">储值卡</a-select-option>
                    <a-select-option value="4" label="次票">礼品兑换卡</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="介质" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.mediaType" style="width: 100%" placeholder="请选择介质"
                            option-label-prop="label">
                    <a-select-option value="1" label="电子卡">电子卡</a-select-option>
                    <a-select-option value="2" label="实体卡">实体卡</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="买方类型" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.buyCustomerType" style="width: 100%" placeholder="请选择买方类型"
                            option-label-prop="label">
                    <a-select-option value="1" label="公司">公司</a-select-option>
                    <a-select-option value="2" label="个人">个人</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            <!-- </a-row> -->
            <!-- <a-row> -->
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="出库批次号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-input v-model="queryData.code" placeholder="请输入出库批次号" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="客户" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-select allowClear v-model="queryData.buyCustomerId" show-search
                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                            style="width: 100%" placeholder="请选择客户"
                            :default-value="null" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="当前客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.staffIdList" style="width: 100%" mode="multiple" placeholder="请选择当前客户经理" :default-value="null" :filter-option="filterOption" option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="出库客户经理" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
                  <a-select v-model="queryData.salespersonIdList" style="width: 100%" mode="multiple"
                            placeholder="请选择出库客户经理" :default-value="null" :filter-option="filterOption"
                            option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in users"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="6" :sm="24" style="max-width: 400px;">
                <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                  <a-range-picker :value="dateValue" :ranges="{
                                        '今天': [moment(), moment()],
                                        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                           '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    }" @change="dateChange"/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" style="margin-bottom: 10px;">
                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                          @click="search">查询
                </a-button>

              </a-col>
            </a-row>

            <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至
              {{ queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项
            </div>
          </div>

        </a-form>

      </div>


      <div>
        <p class="spin-content">{{totalText}}</p>
        <a-table :columns="columns"  :dataSource="dataSource" bordered :loading="tableLoading" :pagination="false"   :scroll="{ x: 1500 }">
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="orderTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="expectReturnTime" slot-scope="text">{{ formatDate(text,1) }}</template>
          
          <template slot="type" slot-scope="text">
            <span v-if="text == 1">次票</span>
            <span v-if="text == 3">储值卡</span>
            <span v-if="text == 4">礼品兑换卡</span>
          </template>
          <template slot="mediaType" slot-scope="text">
            <span v-if="text == 1">电子卡</span>
            <span v-if="text == 2">实体卡</span>
          </template>
          <template slot="buyCustomerType" slot-scope="text">
            <span v-if="text == 1">公司</span>
            <span v-if="text == 2">个人</span>
          </template>
          <template slot="amount" slot-scope="text,record">
            <span v-if="text">{{text * record.outSize}}</span>
            <span v-else>0</span>
          </template>

            <!--  税费 -->
          <template slot="outCardTaxList" slot-scope="text">
            <div>
              <div v-if="text">
                <div v-for="(item,index) in text" :key="item">
                  <p>{{item.amount}} * {{ text[index].taxRate }}%</p>
                </div>
              </div>
              <div v-else>-</div>
            </div>
          </template>

          <template slot="outCardCom" slot-scope="text">
            <p>
              <span v-if="text">{{text.amount}}</span>
              <span v-else>-</span>
            </p>
          </template>
          <template slot="outCardOxp" slot-scope="text">
              <p>
                <span v-if="text">{{text.amount}}</span>
                <span v-else>-</span>
              </p>
          </template>

          <template slot="outCardPre" slot-scope="text">
            <p>
              <span v-if="text">{{text.outCardPre}}</span>
              <span v-else>-</span>
            </p>
          </template>

          <template slot="outCardRepayList" slot-scope="text">
            <div>
              <div v-if="text">
                <div v-for="item in text" :key="item">
                  <p>{{item.amount}}</p>
                </div>
              </div>
              <div v-else>-</div>
            </div>
          </template>

          <template slot="createTime" slot-scope="text">{{formatDate(text)}}</template>

        </a-table>
        <div class="page-box">
          <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer/>
        </div>


      </div>

    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import {request} from '@/utils/request'
import {publicMixin} from '@/mixin/public'

const columns = [
  {title: '序号', customRender: (text, record, index) => index + 1, align: 'center', width: 80,},
  {
    title: '出库批次号',
    dataIndex: 'code',
    width: 220,
    align: 'center'
  },
  {
    title: '客户名称',
    dataIndex: 'buyCustomerName',
    width: 200,
    align: 'center'
  },
  {
    title: '礼品卡名称',
    dataIndex: 'cardName',
    width: 120,
    align: 'center'
  },
  {
    title: '出库客户经理',
    dataIndex: 'salespersonName',
    width: 120,
    align: 'center'
  },
  {
    title: '当前客户经理',
    dataIndex: 'customerStaffName',
    width: 120,
    align: 'center'
  },
  {
    title: '合计实收金额',
    dataIndex:'amount',
    scopedSlots: {customRender: 'amount'},
    width: 120,
    align: 'center'
  },
  {
    title: '数量',
    dataIndex: 'outSize',
    width: 100,
    align: 'center'
  },
  {
    title: '增值金额/张',
    dataIndex: 'quota',
    scopedSlots: {customRender: 'quota'},
    width: 120,
    align: 'center'
  },
  {
    title: '线上充值/张',
    dataIndex: 'amountOnLine',
    width: 120,
    align: 'center'
  },
  {
    title: '线下充值/张',
    dataIndex: 'amountOffLine',
    width: 120,
    align: 'center'
  },
  {
    title: '实收金额/张',
    dataIndex: 'amount',
    width: 120,
    align: 'center'
  },
  {
    title: '回款金额',
    dataIndex: 'outCardRepayList',
    scopedSlots: {customRender: 'outCardRepayList'},
    width: 120,
    align: 'center'
  },
  {
    title: '是否需要开票',
    dataIndex: 'invoiceName',
    width: 100,
    align: 'center'
  },
  {
    title: '开票状态',
    dataIndex: 'invoiceStatusName',
    width: 100,
    align: 'center'
  },
  {
    title: '税费',
    dataIndex: 'outCardTaxList',
    scopedSlots: {customRender: 'outCardTaxList'},
    width: 150,
    align: 'center'
  },
  {
    title: '提成',
    dataIndex: 'outCardCom',
    scopedSlots: {customRender: 'outCardCom'},
    width: 120,
    align: 'center'
  },
  {
    title: '业务支出费用',
    dataIndex: 'outCardOxp',
    scopedSlots: {customRender: 'outCardOxp'},
    width: 120,
    align: 'center'
  },
  {
    title: '预计回款时间',
    dataIndex: 'expectReturnTime',
    scopedSlots: {customRender: 'expectReturnTime'},
    width: 120,
    align: 'center'
  },
  
  // {
  //   title: '业务支出税后',
  //   dataIndex: 'outCardPre',
  //   scopedSlots: {customRender: 'outCardPre'},
  //   width: 120,
  //   align: 'center'
  // },
  {
    title: '卖方',
    dataIndex: 'sellerName',
    width: 200,
    align: 'center'
  },
  {
    title: '卡类型',
    dataIndex: 'type',
    scopedSlots: {customRender: 'type'},
    width: 150,
    align: 'center'
  },
  {
    title: '介质类型',
    dataIndex: 'mediaType',
    scopedSlots: {customRender: 'mediaType'},
    width: 150,
    align: 'center'
  },
  {
    title: '出库人',
    dataIndex: 'staffName',
    width: 150,
    align: 'center'
  },
  {
    title: '出库时间',
    dataIndex: 'createTime',
    scopedSlots: {customRender: 'createTime'},
    width: 150,
    align: 'center'
  },
]

export default {
  name: 'QueryList',
  mixin: [publicMixin],
  data() {
    return {
      totalText:"",
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],

      // 筛选
      queryData: {
        page: 0,
        size: 10,
      },

      tableLoading: false,
      downLoading: false,
      tabCurrent: 1,
      downCurrent: 1,
      downTotal: 0,
      tabTotal: 0,
      currentPage: 1,
      total: 0,
      brandList: [],
      pdtCatList: [],
      dateValue: "",
      users:[]
    }
  },
  mounted() {
    // 列表
    this.getData();
    // 销售
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/staff/list/1', 'get').then(res => {
      this.users = res.data.data
    })
    // 客户列表
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
      this.customerData = res.data.data
    })
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onShowSizeChange(current, pageSize) {
      this.queryData.size = pageSize;
      this.getData()
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    search(){
      this.queryData.page = 0;
      this.getData()
    },
    // 获取列表
    getData() {
      this.tableLoading = true;
      console.log(this.queryData.page,'this.queryData.page111')
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/out/list/cost', 'post', this.queryData).then(res => {
        this.tableLoading = false;
        let data = res.data.data;
        console.log(this.queryData.page,'this.queryData.page')
        if (res.data.code == 200) {
          this.dataSource = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })

      let params = JSON.parse(JSON.stringify(this.queryData));
      delete params.page
      delete params.size
      console.log(params,'params')
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/out/list/cost/count', 'post', params).then(res => {
        this.totalText = res.data.data;
      })
    },
    tabPageChange(page) {
      this.queryData.page = page - 1;
      this.tabCurrent = page;
      this.getData()
    },
    exportTask() {
      this.$message.loading('处理中')
      let params = this.queryData;
      params.remarks = ''
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/shop/exportBrand', 'post', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('导出成功！')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    // 格式化年月日
    formatDate(timestamp,type) {
      if(!timestamp) return '-'
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      // const seconds = date.getSeconds().toString().padStart(2, '0');
      let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
      if(type == 1) {
        result = year + '-' + month + '-' + day 
      }
      return result
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    reset() {
      this.queryData = {
        page: 0,
        size: 10,
      }
      this.dateValue = ''
      this.getData()
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}

.spin-content {
  color: #5542F6;
  margin-top: 10px;
}
</style>
  